<template>
  <div class="w-full h-screen">

    <div class="fixed top-0 left-0 w-full h-screen flex items-center justify-center  z-[999] p-5" v-if="showSurvey">
      <div class="absolute top-0 left-0 w-full h-screen bg-black/40" @click="closeSurvey()" />
      <div class="w-full h-full max-w-5xl max-h-[980px] bg-white rounded-md relative z-50">
        <img src="@/assets/images/close.png" alt="" class="w-5 cursor-pointer absolute z-50 right-5 top-5" @click="closeSurvey()">
        <iframe :src="surveyLink" frameborder="0" class=" w-full h-full rounded-md"></iframe>
      </div>
    </div>
    <MainLayout>
      <div class="w-full h-full border-gray-400 bg-white rounded-3xl flex flex-col items-start justify-start p-5 px-10">
        <div class="mb-5 text-base">
          <p><span class="text-2xl font-bold text-gray-700 mr-5">Questionnaire</span> Your forms are based on your initial questionnaire. </p>
        </div>
        <div class="w-full mb-5">
          <div class="w-full h-4 rounded-full bg-gray-100  overflow-hidden mb-1">
            <div class="h-full bg-primary rounded-full" :style="{width:`${this.surveysCompletedPercentage}%`}"></div>
          </div>
          <p class="w-full text-right text-gray-600">
            {{this.surveysCompletedPercentage}}%
          </p>
        </div>
        <div class="w-full max-w-2xl h-12 mx-auto rounded-full bg-gray-100 grid grid-cols-3">
          <div
            class="tab"
            v-for="(t,i) in tabs"
            :key="JSON.stringify(t)"
            :class="selectedTab===i?'active-tab':''"
            @click="selectedTab=i"
            >{{ t.name }}</div>
        </div>
        <div class="w-full" v-if="selectedTab===0">
          <div class="w-full grid grid-cols-4 gap-10 p-5" v-if="surveys.length>0">
            <!-- Survey Item -->
            <div
              v-for="survey in surveys"
              :key="survey.id"
              class="survey-item">
              <div>
                <div class="flex gap-2 mb-5">
                  <img src="../../img/survey_hero.png" alt="hero" class="w-10 h-10">
                  <div>
                    <p class="font-medium text-[#475F4A]">{{ survey.title }}</p>
                    <p class="text-xs text-gray-400">{{ getTime(survey.last_updated_at) }}</p>
                  </div>
                </div>
                <p class="font-light text-sm">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio, explicabo.
                </p>
              </div>

              <!-- if Not Started -->
              <div class="flex items-center justify-between" v-if="!this.surveysCompleted[survey.id]">
                <button class="start-btn" :class="isParent?'bg-gray-400 border-gray-700 hover:bg-gray-400':'bg-white border-primary hover:bg-primary/80'" @click="startSurvey(survey._links.display)" :disabled="isParent">
                  Start
                  <img src="../../img/play.png" alt="tick" class="w-3">
                </button>
              </div>

              <!-- if Completed -->
              <div class="flex items-center justify-between" v-if="this.surveysCompleted[survey.id]">
                <button class="complete-btn" :class="isParent?'bg-gray-400 border-gray-700 hover:bg-gray-400':'bg-primary border-primary hover:bg-primary/80'" @click="startSurvey(survey._links.display)" :disabled="isParent">
                  Complete
                  <img src="../../img/tick.png" alt="tick" class="w-3">
                </button>
                <p class="text-sm text-gray-400">{{ new Date(survey.last_updated_at).toLocaleDateString() }}</p>
              </div>
            </div>
          </div>
          <div class="w-full p-10 flex items-center justify-center text-primary" v-else>
            No Survey Found !!
          </div>
        </div>
        <div class="w-full" v-if="selectedTab===1">
          <div class="w-full p-10 flex items-center justify-center text-primary">
            No Survey Found !!
          </div>
        </div>
        <div class="w-full" v-if="selectedTab===2">
          <div class="w-full p-10 flex items-center justify-center text-primary">
            No Survey Found !!
          </div>
        </div>

      </div>
    </MainLayout>
</div>
</template>

<script>
import ellipse from "../../img/survey_hero.png";
import BroadcastFeed from "./BroadcastFeed.vue";
import CareerProfileFrame from "./CareerProfileFrame.vue";
import MainLayout from "../layouts/MainLayout.vue";
import { getAssociatedData } from "../AssociatedData.js";
export default {
  name: "HomeFeedStudentDefault",
  components: {

    BroadcastFeed,
    CareerProfileFrame,
    ellipse,
    MainLayout
  },
  props: [
    "jj",
    "leftNavigationBarProps",
    "topNavigationBarProps",
    "broadcastFeedProps",
    "careerProfileFrameProps",
  ],

  data() {
    return {
      surveyLink:'',
      showSurvey:false,
      ellipse,
      surveys: [],
      surveyAnswers: [],
      surveysCompleted: {},
      surveysCompletedPercentage: 0,
      selectedTab:0,
      tabs: [
        { name: "All Forms" },
        { name: "Interests" },
        { name: "Assessments" },
      ],
      isParent:false
    };
  },

  methods: {
    activateTab(index) {
      this.tabs.forEach((tab, tabIndex) => {
        tab.active = index === tabIndex;
      });
    },
    async buildSurveyCompletedMap() {
      const ad = await getAssociatedData();
      console.log('ad',ad);
      
      if (ad) {
        let completed = 0;
        for (let i = 0; i < this.surveys.length; i++){
          if (ad.typeFormResultMap[this.surveys[i].id]) {
            completed++;
            this.surveysCompleted[this.surveys[i].id] = true;
          } else {
            this.surveysCompleted[this.surveys[i].id] = false;
          }
        }
        this.surveysCompletedPercentage = completed/this.surveys.length * 100;
      }
    },
    async fetchSurveys() {
      try{
        const response = await this.$request('/v1/surveys', 'GET');
        this.surveys = await response.data.data.items;
      }catch(err){
        console.log('Survey Fetch Error', err);
      }
    },
    async setTypeFormWebhooks() {
      for ( let i = 0; i < this.surveys.length; i++ ) {
        await this.setTypeFormWebhook(this.surveys[i].id);
      }
    },
    async setTypeFormWebhook(surveyId) {
      const id = localStorage.getItem("user.id");
      const wurl = "/v1/setSurveyWebhook/" + surveyId + "/" + id;
      const response = await this.$request(wurl, 'GET');
      //const response = await this.$request('/v1/setSurveyWebhook/IBTtK8kC', 'GET');
    },
    async fetchSurveyAnswers() {
      const response = await this.$request('/v1/getSurveyResponses/IBTtK8kC', 'GET');
      this.surveyAnswers = await response.data;
    },
    startSurvey(link){
      this.surveyLink = link;
      this.showSurvey = true;
    },
    closeSurvey(){
      this.showSurvey = false
    },
    getTime(time){
      const now = new Date();
      const prev = new Date(time);
      const t = now - prev
      let text = ''
      if(t<2000){
        text = 'just now'
      }else if(t>2000){
        let seconds = Math.floor(t/1000);
        let minutes = 0;
        let hours = 0;
        let days = 0;
        let months = 0;
        let years = 0;
        if(seconds>=60){
          minutes = Math.floor(seconds/60);
        }
        if(minutes>=60){
          hours = Math.floor(minutes/60);
        }
        if(hours>=24){
          days = Math.floor(hours/24);
        }
        if(days>30){
          months = Math.floor(days/30);
        }
        if(months>=12){
          years = Math.floor(months/12);
        }

        if(years>0){
          text = years + ' years ago'
        }else if(months>3){
          text = months + ' months ago'
        }else{
          if(days>0){
            text = days + ' days ago'
          }else if(hours>0){
            text = hours + ' hours ago'
          }else if(minutes>0){
            text = minutes + ' minutes ago'
          }else if(seconds>0){
            text = seconds + ' seconds ago'
          }
        }

      }
    }
  },
  async mounted() {
    try{
      const ad = await getAssociatedData();
      this.isParent = ad.roles.includes('parent')
      await this.fetchSurveys();
      await this.setTypeFormWebhooks();
      await this.buildSurveyCompletedMap();
    }catch(err){
      console.log('Err', err);
    }

  },
};
</script>
<style scoped>
.tab{
  @apply w-full h-full flex items-center justify-center rounded-full text-gray-500 text-base cursor-pointer transition-all duration-150;
}
.active-tab{
  box-shadow: 0 0 10px #00000020;
  @apply bg-white text-primary font-semibold text-lg
}
.survey-item{
  box-shadow: 0 0 5px #00000020;
  @apply w-full h-[240px] rounded-xl bg-white p-5 flex flex-col justify-between
}
.complete-btn{
  @apply w-28 h-8 rounded-full flex items-center justify-center gap-2 text-white text-sm transition-all duration-150  
}
.start-btn{
  @apply w-28 h-8 rounded-full flex items-center justify-center gap-5 text-primary text-sm border  cursor-pointer hover:text-white transition-none duration-150
}
</style>
