export const formatDate = (date) => {
    const opts = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString('en-US', opts);
}

export const timeSince = (postDate) => {
    const currentDateTime = new Date();
    const postDateTime = new Date(postDate);

    const seconds = Math.floor((currentDateTime - postDateTime) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
        return interval + (interval === 1 ? " year ago" : " years ago");
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return interval + (interval === 1 ? " month ago" : " months ago");
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        return interval + (interval === 1 ? " day ago" : " days ago");
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        return interval + (interval === 1 ? " hour ago" : " hours ago");
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return interval + (interval === 1 ? " minute ago" : " minutes ago");
    }
    return Math.floor(seconds) + (Math.floor(seconds) === 1 ? " second ago" : " seconds ago");
}

export const checkValidURL = (url) => {
    try {
        new URL(url);
        return true;
    } catch (_) {
        return false;
    }
}

export const checkIfValidLink = (url, platform) => {
    if (platform === 'youtube') {
        const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})$/;
        return youtubeRegex.test(url)
    }
}
